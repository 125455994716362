:root {
  --radius: 5px;
  --border-width: 1px;
}

.btn {
  border-radius: var(--radius);
  border: var(--border-width) solid var(--primary-Color, #226db4);
  /* Text lg/Semibold */
  padding: 7px 20px;

  position: relative;
  transition: 0.5s;
  z-index: 1;
}

.btn-common-style {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  cursor: pointer;
}

.btn_bgPrimaryColor {
  border-radius: var(--radius);
  border: var(--border-width) solid var(--primary-Color, #226db4);
  padding: 7px 20px;
  position: relative;
  transition: 0.5s;
  z-index: 1;
}

.btn-primary {
  background: #fff;
  color: var(--primary-Color, #226db4);
}

.btn-primary-primary {
  background: var(--primary-Color, #226db4);
  color: var(--white-text-color, #fff);
}

.btn-primary-primary1 {
  color: rgba(172, 66, 255, 1);
}
.btn-primary1 {
  background: rgba(172, 66, 255, 1);
  color: #fff;
  border: 1px solid #fff;
}

.btn-primary-primary2 {
  background: #fff;
  color: #226db4;
  border: var(--border-width) solid #226db4;
}
.btn-primary-primary3 {
  background: transparent;
  color: #fff;
  border: var(--border-width) solid #fff;
}

.btn_navbar_padding {
  padding: 7px 12px;
}

.btn:before,
.btn_bgPrimaryColor:before {
  border-radius: 0 var(--radius) var(--radius) 0;
  right: 0;
  border-left: none;
}

.btn::after,
.btn_bgPrimaryColor:after {
  border-radius: var(--radius) 0px 0px var(--radius);
  left: 0;
  border-right: none;
}

.btn:after,
.btn:before {
  background: var(--primary-Color, #226db4);
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  transition: 0.5s;
  width: 0;
  z-index: -1;
}

.btn_bgPrimaryColor:after,
.btn_bgPrimaryColor:before {
  background: #fff;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  transition: 0.5s;
  width: 0;
  z-index: -1;
}

/* .btn-primary:focus, */
.btn-primary:active,
.not-loading.btn-primary:hover {
  background: var(--primary-Color, #226db4);
  box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
  color: #fff;
  transform: translateY(-5px);
}

/* .btn-primary-primary:focus, */
.btn-primary-primary:active,
.not-loading.btn-primary-primary:hover {
  background: #fff;
  box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
  color: var(--primary-Color, #226db4);
  transform: translateY(-5px);
}

.not-loading.btn:hover:after,
.not-loading.btn:hover:before,
.not-loading.btn_bgPrimaryColor:hover:after,
.not-loading.btn_bgPrimaryColor:hover:before {
  width: 50%;
}

.btn_common {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.fullwidth {
  width: 100%;
}

@media only screen and (max-width: 568px) {
  .btn-common-style {
    font-size: 16px;
  }
  .btn_navbar_padding {
    padding: 4px 12px;
  }
}
